import React from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import styled from "styled-components";

import siteConfig from "../../data/SiteConfig";
import { getPerformerSortName } from "../utils/strings";

import Page from "../components/Layout/Page";
import { FixedWidthContainer } from "../components/Layout/ContentContainers";
import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

import PerformerList from "../components/PerformerList";

class PodcastsPage extends React.Component {
  getMetaUrl() {
    return (
      `${siteConfig.siteUrl}${this.props.location.pathname}` ||
      siteConfig.siteUrl
    );
  }

  getMetaTitle() {
    let pageTitle = siteConfig.siteTitle;

    pageTitle = `Podcasts | Lineup | ${pageTitle}`;

    return pageTitle;
  }

  render() {
    if (!this.props.data.allFestPerformers) {
      return (
        <Page title="Podcast Lineup" showTitle={false}>
          <Helmet title={this.getMetaTitle()}>
            <meta property="og:url" content={this.getMetaUrl()} />
            <meta property="og:title" content={this.getMetaTitle()} />
          </Helmet>

          <FixedWidthContainer style={{ marginBottom: "72px" }}>
            <h3>Coming soon</h3>
            <p>Check back here closer to THE FEST!</p>
          </FixedWidthContainer>
        </Page>
      );
    }

    const performers = this.props.data.allFestPerformers.edges.sort((a, b) => {
      const aName = a.node.title.rendered;
      const bName = b.node.title.rendered;
      if (aName === bName) {
        return 0;
      }

      return getPerformerSortName(aName) > getPerformerSortName(bName) ? 1 : -1;
    });

    return (
      <Page title="Podcast Lineup" showTitle={false}>
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>

        <FixedWidthContainer
          style={{ textAlign: "center", marginBottom: "36px" }}
        >
          <h1>Podcast Lineup</h1>
        </FixedWidthContainer>
        <PerformerListContainer>
          <PerformerList performers={performers} urlPrefix="podcasts" />
        </PerformerListContainer>
      </Page>
    );
  }
}

const PerformerListContainer = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    max-width: 95%;
    margin: 0 auto;
  `};
`;

export default PodcastsPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PodcastsPageQuery {
    allFestPerformers(filter: { acf: { type: { eq: "podcast" } } }) {
      edges {
        node {
          id
          festId
          title {
            rendered
          }
          slug
          acf {
            hometown
            photo {
              url
              sizes {
                medium
                medium_width
                medium_height
              }
            }
          }
        }
      }
    }
  }
`;
